import { defineComponent, reactive, ref } from '@vue/composition-api';
import type { Ref, Data } from '@vue/composition-api';

//import { parseTime } from '@/public/methods';

import Calendar from './calendar';
import BgmIcon from './BgmIcon';
import type { Route } from 'vue-router';
import FetchData from '@/components/FetchData';

interface PlaylistData {
    onlyid: string | number;
    title: string;
    description: string;
    image: string;
    count?: number;
    url?: string;
}

export default defineComponent({
    components: { Calendar, BgmIcon, FetchData },
    setup() {
        const systemListData: PlaylistData[] = [
            {
                onlyid: '!::system::everyday',
                title: '每日推荐',
                description: '今日份的推荐', //parseTime(new Date().getTime() / 1000),
                image: '::::everyday',
                count: 10,
                url: '::::everyday',
            },
            {
                onlyid: '!::system::bgm',
                title: '背景音乐',
                description: '本站的背景音乐',
                image: '::::bgm',
                count: 5,
                url: '::::bgm',
            },
        ];
        const playlistData: Ref<PlaylistData[]> = ref([...systemListData]);
        const data: Data = reactive({
            list: [],
        });
        const formatPath = (url: string): string => {
            if (typeof url !== 'string') {
                url = '';
            }
            let res = '/music';
            if (url.substring(0, 2) === '::') {
                const [_, root, model] = url.split('::');
                if (!model) {
                    alert('路由地址解析错误');
                    return '';
                }
                switch (root) {
                    case '':
                        res += '/sys';
                        break;
                    default:
                        res += '/' + root;
                    //
                }
                res = res + '/' + model;
                return res;
            } else {
                if (url.substring(0, 1) === '/') {
                    res = url;
                } else {
                    res += '/playlist/' + url;
                }
            }
            return res;
        };
        const touchData = (data: PlaylistData[]) => {
            const pl = [...systemListData];
            for (const item of data) {
                pl.push(item);
            }
            playlistData.value = pl;
        };
        return { playlistData, data, formatPath, touchData };
    },
});
