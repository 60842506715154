import { defineComponent } from '@vue/composition-api';
import type {} from '@vue/composition-api';

import Card from '@/components/Card';
import PageNodeMain from '@/components/PageNodeMain';
import PageNodeSub from '@/components/PageNodeSub/index.vue';

import MusicNvod from '@/components/Article/MusicNvod';
import MusicList from '@/components/Article/MusicList';
import MusicCategoryLite from '@/components/Article/MusicCategoryLite';
import SearchLite from '@/components/Article/SearchLite';
import MusicTagLite from '@/components/Article/MusicTagLite';
import MusicRankLite from '@/components/Article/MusicRankLite';

export default defineComponent({
	components: {
		Card,
		PageNodeMain,
		PageNodeSub,
		MusicNvod,
		MusicList,
		MusicCategoryLite,
		SearchLite,
		MusicTagLite,
		MusicRankLite,
	},
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		return { t };
	},
});
