var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"music-rank-lite-component"},[_c('div',{staticClass:"rank-head-container"},[_c('ul',{staticClass:"rank-head-list flex-evenly"},_vm._l((_vm.column),function(item){return _c('li',{key:item.key,staticClass:"rank-head-item"},[_c('div',{staticClass:"item-block",class:{
						'item-block-active': _vm.activeColumn === item.key,
					},on:{"click":function($event){_vm.activeColumn = item.key}}},[_c('span',[_vm._v(_vm._s(item.title))])])])}),0),_c('hr',{staticClass:"line"})]),_c('div',{staticClass:"rank-body-container"},[_c('fetch-data',{key:_vm.activeColumn,attrs:{"area":"MUSIC_LEAGUE","isSkeleton":true,"param":{ league: _vm.activeColumn }},on:{"touchData":_vm.touchData},scopedSlots:_vm._u([{key:"error123",fn:function(){return [_c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center","justify-content":"center","padding":"2em 0"}},[_c('empty',{attrs:{"text":"暂无数据"}})],1)]},proxy:true}])},[_c('ul',{staticClass:"rank-body-list"},_vm._l((_vm.rankData),function(item,index){return _c('li',{key:item.onlyid,staticClass:"rank-body-item"},[_c('router-link',{staticClass:"item-block",attrs:{"data-not-allow-skeleton-prase":"","to":item.onlyid.substring(0, 2) === '!!'
								? ''
								: {
										name: 'MusicDetail',
										params: {
											onlyid: item.onlyid,
										},
								  }}},[_c('div',{staticClass:"item-main"},[_c('span',{staticClass:"index"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{staticClass:"item-sub"},[_c('span',[_vm._v(_vm._s(item.author || '未知歌手'))])])])],1)}),0)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }