/*
(() => {
    let a = setInterval(() => {}, 1000);
    for (let i = 0; i <= a; i++) {
        clearInterval(i);
    }
})();
*/

import { defineComponent, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import FetchData from '@/components/FetchData';
import { Empty } from '@/components/UI';

export default defineComponent({
	components: { FetchData, Empty },
	setup() {
		const column: Ref = ref([
			{
				key: 'new',
				title: '新歌榜',
			},
			{
				key: 'hot',
				title: '热歌榜',
			},
			{
				key: 'rise',
				title: '飙升榜',
			},
		]);
		const activeColumn: Ref<string> = ref('new');
		const rankData: Ref<any[]> = ref([
			{
				onlyid: '!!1',
				title: '标题占拉符',
			},
			{
				onlyid: '!!2',
				title: '标题占拉符111222333',
			},
			{
				onlyid: '!!3',
				title: '标题占拉符222',
			},
		]);
		const touchData = (data: any) => {
			rankData.value = data;
			//console.log(data);
		};
		return { column, activeColumn, rankData, touchData };
	},
});
