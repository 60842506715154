import { defineComponent, ref, Ref } from '@vue/composition-api';

import FetchData from '@/components/FetchData';

interface TagDataChildren {
	onlyid: string | number;
	tagName: string;
	category: string;
}

interface TagData {
	onlyid: string | number;
	categoryName: string;
	children: TagDataChildren[];
}

export default defineComponent({
	components: { FetchData },
	setup() {
		const tagData: Ref<TagData[]> = ref([]);
		const touchData = (data: TagData[]) => (tagData.value = data);
		return { tagData, touchData };
	},
});
