var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"music-tag-lite-component"},[_c('div',{staticClass:"tag-container"},[_c('fetch-data',{attrs:{"area":"MUSIC_TAG_ALL","isCache":true},on:{"touchData":_vm.touchData}},[_c('div',{staticClass:"data-mt-container"},[_c('ul',{staticClass:"mtc-list"},_vm._l((_vm.tagData),function(item){return _c('li',{key:item.onlyid,staticClass:"mtc-item"},[_c('div',{staticClass:"item-block"},[_c('h4',{staticClass:"mt-category-title"},[_vm._v(" "+_vm._s(item.categoryName)+" ")]),_c('ul',{staticClass:"mt-category-children mt-list"},_vm._l((item.children),function(item1){
var _obj;
return _c('li',{key:item1.onlyid,staticClass:"mt-item"},[_c('router-link',{staticClass:"item-link",attrs:{"to":('' + item.onlyid).substring(0, 2) === '!!'
													? ''
													: {
															name: 'MusicTag',
															query: ( _obj = {}, _obj[item.onlyid] = item1.onlyid, _obj.page = 1, _obj ),
													  }}},[_c('span',[_vm._v(_vm._s(item1.tagName))])])],1)}),0)])])}),0)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }