import {
    defineComponent,
    onBeforeUnmount,
    onMounted,
    reactive,
    ref,
} from '@vue/composition-api';
import type { Ref, Data } from '@vue/composition-api';
import FetchData from '@/components/FetchData';

import { SvgLeft, SvgRight } from './svg';

interface NvodListData {
    readonly onlyid: string | number;
    title: string;
    image: string;
    url?: string;
}

let timer: number | undefined = undefined;
const twice = 6000;
export default defineComponent({
    components: { FetchData, SvgLeft, SvgRight },
    setup() {
        const nvodListData: Ref<NvodListData[]> = ref([
            {
                onlyid: 'adswresdfewsdggs',
                title: '聆听彼此，海报',
                image: '/static/testimg/b1.jpg',
                url: '',
            },
            {
                onlyid: 'adswresdfewsdggasdrqwrqs',
                title: '聆听彼此，海报',
                image: '/static/testimg/b2.jpg',
                url: '',
            },
        ]);
        const touchData = (data: NvodListData[]) => {
            nvodListData.value = data;
        };
        const data: any = reactive({
            activeNum: 0,
            isToNextForTransition: true,
        });
        const changeNum = (num: number) => {
            data.isToNextForTransition = num >= data.activeNum;
            clearInterval(timer);
            if (
                Array.isArray(nvodListData.value) &&
                typeof nvodListData.value[num] !== 'object'
            ) {
                num = num % nvodListData.value.length;
            }
            if (num < 0) {
                num = nvodListData.value.length + num;
            }
            data.activeNum = num;
            timer = setInterval(autoChangeNum, twice);
        };
        const autoChangeNum = () => {
            data.isToNextForTransition = true;
            if (data.activeNum >= nvodListData.value.length - 1) {
                data.activeNum = 0;
            } else {
                data.activeNum++;
            }
        };
        onMounted(() => {
            timer = setInterval(autoChangeNum, twice);
        });
        onBeforeUnmount(() => {
            clearInterval(timer);
        });
        return { nvodListData, data, changeNum, touchData };
    },
});
