import { defineComponent, ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import { Btn } from '@/components/UI';

export default defineComponent({
	props: {
		submit: {
			//提交到哪个URL{url:'/blog/search/{}',method:'post|get'}
			type: Object,
			required: false,
		},
		area: {
			type: String,
			required: false,
		},
		placeholder: {
			type: String,
			required: false,
		},
	},
	components: { Btn },
	setup(props: any, { root }: any) {
		const { $router } = root;
		const t = root.$t.bind(root);
		const url = props?.submit?.url || '/search';
		const area = props?.area || '';
		const method = props?.submit?.method || 'get';
		const value: Ref<string> = ref('');

		const valFilter = (val: string): string => {
			return val.replaceAll('/', '').replaceAll('\\', '').replaceAll('?', '').replaceAll('&', '').replaceAll('=', '').replaceAll('<', '').replaceAll('>', '').replaceAll('+', ' ');
		};
		const doSearch = () => {
			const path = url; //url.replace('{}', valFilter(value.value) || '');
			//const path = url.replace('{}', '');
			$router.push({
				path,
				params: {
					search: value.value, //关键字
					area, //在哪个区域内搜索
					method, //方法，get或post
				},
				query: {
					search: value.value, //关键字
					area, //在哪个区域内搜索
					method, //方法，get或post
				},
			});
		};
		return { value, doSearch, t };
	},
});
