








































import { defineComponent } from '@vue/composition-api';
import { imgBenchUrl } from './bench-url';
export default defineComponent({
    setup() {
        return { imgBenchUrl };
    },
});
